
<template>
  <div class="edit">
    <VueEditor v-model="content" useCustomImageHandler @image-added="handleImageAdded"></VueEditor>
  </div>
  <div v-html="content"></div>
</template>

<script>
import { VueEditor } from "vue3-editor";
import  {ref} from 'vue'
export default {
  name: "About",
  components: {
    VueEditor,
  },
  setup() {
    let content = ref("")
    console.log(content.value);
    let handleImageAdded = () =>{
        console.log("上传图片");
    }
    return {
      content,
      handleImageAdded
    };
  },
};
</script>

<style lang='scss' >
.edit{
  width: 800px;
}
</style>
